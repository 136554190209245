import { render, staticRenderFns } from "./App.vue?vue&type=template&id=39972c35&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VApp,VBtn,VCard,VCardSubtitle,VCardText,VCol,VContainer,VForm,VImg,VMain,VRadio,VRadioGroup,VRow,VSelect,VTextarea})
