<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <template>
              <v-card
                  class="mx-auto"
                  max-width="800"
                  max-height="1000"
              >
                <v-img

                    height="200px"
                    width="400px"
                    src="logo2.jpg"
                />
                <template v-if="dealID">
                  <template v-if="dealStatus!=='success'">
                    <v-card-subtitle class="text--body-2">Уважаемый {{ clientName }}, пожалуйста оцените качество оказанных услуг</v-card-subtitle>
                    <v-card-text>
                      <v-form>
                        <p>Оценка</p>
                        <v-radio-group
                            row
                            v-model="grade"
                            small
                        >
                          <v-radio
                              v-for="n in 5"
                              :key="n"
                              :label="`${6 - n}`"
                              :value="`${6 - n}`"
                              color="red"
                              small
                          ></v-radio>
                        </v-radio-group>
                        <p>Вежлив ли был наш сотрудник?</p>
                        <v-radio-group
                            row
                            v-model="gentle"
                            small
                        >
                          <v-radio
                              v-for="n in ['Да', 'Нет']"
                              :key="n"
                              :label="`${n}`"
                              :value="`${n}`"
                              color="red"
                              small
                          ></v-radio>
                        </v-radio-group>
                        <v-select
                            outlined
                            label="Откуда о нас узнали?"
                            v-model="sourceSelect"
                            :items="sourceTypes"
                            item-text="VALUE"
                            item-value="ID"
                            small
                        ></v-select>
                        <v-textarea
                            outlined
                            label="Комментарий"
                            v-model="comments"
                            small
                        ></v-textarea>
                        <v-btn
                            color="red"
                            text
                            @click="successDeal"
                        >
                          Отправить
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col>
                        <v-card-subtitle class="text--body-2">Благодарим Вас за оставленный отзыв!</v-card-subtitle>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-else>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-card-subtitle class="text--body-2">Упс... Что то пошло не так, <br> обратитесь в офис компании</v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data: () => ({
    dealID: '',
    gentle: 'Да',
    comments: '',
    sourceSelect: '',
    sourceTypes: [],
    clientName: '',
    url: 'https://ocd.bitrix24.ru/rest/4/ihn1stf85n4uoyss/',
    grade: '5',
    dealStatus: ''
  }),
  methods: {
    async successDeal() {
      try {
        if (this.comments) {
          if (this.grade) {
            let paramsTimeline = {
              fields: {
                "ENTITY_ID": this.dealID,
                "ENTITY_TYPE": "deal",
                "COMMENT": `Клиент ${this.clientName} оставил отзыв о предоставленных услугах!`//
              }
            }
            await axios.post(this.url + 'crm.timeline.comment.add', paramsTimeline)
            let paramsDealUpdate = {
              id: this.dealID,
              fields: {
                UF_CRM_619DD9708A9BA: this.sourceSelect,
                UF_CRM_1587558398137: this.comments,
                UF_CRM_1646840650: this.grade,
                UF_CRM_1646906537: 'N'
              },
              params: {"REGISTER_SONET_EVENT": "Y"}
            }
            if(this.gentle === 'Да') paramsDealUpdate.fields.UF_CRM_1646906537 = 'Y'
            const result = await axios.post(this.url + 'crm.deal.update', paramsDealUpdate)
            if (result.data.result) {
              alert('Благодарим за оставленный отзыв!')
              window.location.href = `https://spa-bitrix.ru/ocd_feedbackform?dealID=${this.dealID}`
            }
            console.log(this.sourceSelect, 'source')
            console.log(this.comments, 'comments')
            console.log(this.grade, 'grade')
            console.log(this.gentle, 'gentle')
            console.log({result})
          } else {
            alert(`Вы не заполнили поле "Оценка"`)
          }
        } else {
          alert(`Вы не заполнили поле "Комментарий"`)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getSourceType() {
      try {
        let params = {
          id: 720
        }
        const field = await axios.post(this.url + 'crm.deal.userfield.get', params)
        console.log({field})
        this.sourceTypes = field.data.result.LIST
      } catch (e) {
        console.log(e)
      }
    },
    async getDeal() {
      try {
        const uri = window.location.href.split('?');
        const dealID = uri[1].split('=')[1]
        this.dealID = dealID
        let params = {
          id: dealID
        }
        let method = 'crm.deal.get'
        const dealInfo = await axios.post(this.url + method, params)
        if (dealInfo.data.result.UF_CRM_1646840650) {
          this.dealStatus = 'success'
        }
        params = {
          id: dealInfo.data.result['CONTACT_ID']
        }
        method = 'crm.contact.get'
        const contactInfo = await axios.post(this.url + method, params)
        this.clientName = `${contactInfo.data.result.NAME}`
        console.log({params})
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.getSourceType()
    await this.getDeal()
  }
};
</script>
